
// export const userBots = [
//     {
//       appointmentName: "Sassy",
//       appointmentID: "BOTID66678030434556",
//       date: "15 Dec, 2022",
//       deployBot: "Deploy Bot",
//       inquery: "View Inquiry",
//       Interactions: "0 / 250 Interactions",
//       plan: "Free Plan",
//       botType: "Appointment Bot",
//       premium: "UPGRADE TO PREMIUM",
//       active:true
//     },
//     {
//       appointmentName: "Hip",
//       appointmentID: "BOTID66678030434501",
//       date: "15 Oct, 2022",
//       deployBot: "Deployed",
//       inquery: "View Inquiry",
//       Interactions: "0 / 250 Interactions",
//       plan: "Premium Plan",
//       botType: "Agent Transfer bot",
//       premium: "UPGRADE TO PREMIUM",
//       active:false
//     },
//     {
//       appointmentName: "Friendly",
//       appointmentID: "BOTID66678030434723",
//       date: "15 Dec, 2022",
//       deployBot: "Deploy Bot",
//       inquery: "View Inquiry",
//       Interactions: "0 / 250 Interactions",
//       plan: "Free Plan",
//       botType: "Inquiry bot",
//       premium: "UPGRADE TO PREMIUM",
//       active:true
//     },
//     {
//       appointmentName: "Bubble",
//       appointmentID: "BOTID66678030434889",
//       date: "15 Dec, 2022",
//       deployBot: "Deployed",
//       inquery: "View Inquiry",
//       Interactions: "0 / 250 Interactions",
//       plan: "Premium Plan",
//       botType: "GPT bot",
//       premium: "UPGRADE TO PREMIUM",
//       active:false
//     },
//     {
//       appointmentName: "Groovy",
//       appointmentID: "BOTID66678030434934",
//       date: "15 Dec, 2022",
//       deployBot: "Deploy Bot",
//       inquery: "View Inquiry",
//       Interactions: "0 / 250 Interactions",
//       plan: "Free Plan",
//       botType: "FoodCourt Bot",
//       premium: "UPGRADE TO PREMIUM",
//       active:true
//     },
//     {
//       appointmentName: "Witty",
//       appointmentID: "BOTID66678030434556",
//       date: "15 Dec, 2022",
//       deployBot: "Deploy Bot",
//       inquery: "View Inquiry",
//       Interactions: "0 / 250 Interactions",
//       plan: "Free Plan",
//       botType: "Agent Transfer Bot",
//       premium: "UPGRADE TO PREMIUM",
//       active:false
//     },
//     {
//       appointmentName: "Funky",
//       appointmentID: "BOTID66678030434501",
//       date: "15 Oct, 2022",
//       deployBot: "Deployed",
//       inquery: "View Inquiry",
//       Interactions: "0 / 250 Interactions",
//       plan: "Premium Plan",
//       botType: "Appointment Bot",
//       premium: "UPGRADE TO PREMIUM",
//       active:true
//     },
//     {
//       appointmentName: "Zap",
//       appointmentID: "BOTID66678030434723",
//       date: "15 Dec, 2022",
//       deployBot: "Deploy Bot",
//       inquery: "View Inquiry",
//       Interactions: "0 / 250 Interactions",
//       plan: "Free Plan",
//       botType: "Agent Transfer Bot",
//       premium: "UPGRADE TO PREMIUM",
//       active:false
//     },
//     {
//       appointmentName: "Light",
//       appointmentID: "BOTID66678030434889",
//       date: "15 Dec, 2022",
//       deployBot: "Deployed",
//       inquery: "View Inquiry",
//       Interactions: "0 / 250 Interactions",
//       plan: "Premium Plan",
//       botType: "Inquiry Bot",
//       premium: "UPGRADE TO PREMIUM",
//       active:true
//     },
//     {
//       appointmentName: "Pulse",
//       appointmentID: "BOTID66678030434934",
//       date: "15 Dec, 2022",
//       deployBot: "Deploy Bot",
//       inquery: "View Inquiry",
//       Interactions: "0 / 250 Interactions",
//       plan: "Free Plan",
//       botType: "GPT Bot",
//       premium: "UPGRADE TO PREMIUM",
//       active:false
//     },
//     {
//       appointmentName: "Bumper",
//       appointmentID: "BOTID66678030434934",
//       date: "15 Dec, 2022",
//       deployBot: "Deploy Bot",
//       inquery: "View Inquiry",
//       Interactions: "0 / 250 Interactions",
//       plan: "Free Plan",
//       botType: "FoodCourt Bot",
//       premium: "UPGRADE TO PREMIUM",
//       active:true
//     },
//   ]

  //setup data
  export const setup = {
    website : [
    {
      setupType : 'customWebsite',
      name : 'Custom website',
      imageUrl : 'customeWebsite.png',
      arrowUrl : 'rightArrow.png'
    },
    {
      setupType : 'formbot',
      name : 'Form Bot',
      imageUrl : 'form-bot.svg',
      arrowUrl : 'rightArrow.png'
    },
    {
      setupType : 'wordpress',
      name : 'Wordpress',
      imageUrl : 'wordpress.png',
      arrowUrl : 'rightArrow.png'
    },
    {
      setupType : 'wix',
      name : 'Wix',
      imageUrl : 'wix.png',
      arrowUrl : 'rightArrow.png'
    },
    {
      setupType : 'squarespace',
      name : 'Squarespace',
      imageUrl : 'squarespace.png',
      arrowUrl : 'rightArrow.png'
    },
    {
      setupType : 'shopify',
      name : 'Shopify',
      imageUrl : 'shopfy.png',
      arrowUrl : 'rightArrow.png'
    },
    {
      setupType : 'weebly',
      name : 'Weebly',
      imageUrl : 'weebly.png',
      arrowUrl : 'rightArrow.png'
    },
    {
      setupType : 'godaddy',
      name : 'Godaddy',
      imageUrl : 'godady.png',
      arrowUrl : 'rightArrow.png'
    },
    {
      setupType : 'magento',
      name : 'Magento',
      imageUrl : 'magento.png',
      arrowUrl : 'rightArrow.png'
    },
    {
      setupType : 'webflow',
      name : 'Webflow',
      imageUrl : 'webflow.png',
      arrowUrl : 'rightArrow.png'
    }
    ],
    mediaApps : [{
      setupType : 'installFB',
      name : 'Install chatbot in Facebook',
      imageUrl : 'fb.png',
      arrowUrl : 'rightArrow.png'
    },
    {
      setupType : 'installTelegram',
      name : 'Install chatbot in Telegram',
      imageUrl : 'telegram.png',
      arrowUrl : 'rightArrow.png'
    },
    {
      setupType : 'installLine',
      name : 'Install chatbot in Line',
      imageUrl : 'line.png',
      arrowUrl : 'rightArrow.png'
    },
    {
      setupType : 'installViber',
      name : 'Install chatbot in Viber',
      imageUrl : 'viber.png',
      arrowUrl : 'rightArrow.png'
    }],
    sdk : [
      {
        setupType : 'iosSdk',
        name : 'iOS SDK',
        imageUrl : 'ios.png',
        arrowUrl : 'rightArrow.png'
      },
      {
        setupType : 'androidSdk',
        name : 'Android SDK',
        imageUrl : 'android.png',
        arrowUrl : 'rightArrow.png'
      }
    ]
  }

export const userBots = []



