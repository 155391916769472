import React, { useState, useEffect } from 'react'
import { API_URL } from '../../../config/params'
import { getDeployedViberList, vibersetup } from '../../../utilities/chatbotMethods'
import { NotificationManager } from 'react-notifications'
import { Link } from 'react-router-dom'
import StepLoader from '../../loaders/StepLoader'
import SetupLoader from '../../loaders/SetupLoader'

function Viber() {

    const [viberData, setViberData] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [isInstalled, setIsInstalled] = useState(false)
    const [enable, setEnable] = useState(true)
    const [viberAccessToken, setViberAccessToken] = useState("")


    const activeCss = "block bg-thmBG w-12 h-6 rounded-full"
    const disableCss = "block w-12 h-6 rounded-full bg-[#00000042]"



    async function handleViberSetup(action) {

        //  alert('hlo')
        setIsLoading(true)
        // {BOTID:"BOTID1622020512038"
        // Botname:"inquiry"
        // Bottype:"0"
        // LineUsername:"@800fvrqi"
        // Token:"ZV7MuEo5QfRzUL0SKlwJ6Q/riowFNV8lxQYHcxWVbEIw8hwkTR4/TIgryW3ZPrjzQpLBPOBKzekAyHm6VYD59f9J+gpWElrUH5E2min7ZX3F+XStr35yhegpljMQryxFVj0b3dzSuJKnvQCilE0GXwdB04t89/1O/w1cDnyilFU="
        // agentID:"AGENT1603259591478"
        // alias:"kgizd646"
        // chatMode:"bot" 
        // displayName:"HelloSP"
        // isActive:true}
        // if (entoken){setLineAccessToken(entoken)}
        // https://chatapi.viber.com/pa/send_message

        // fetchurl="http://localhost:5000/appycx-beta/us-central1/LineSetupAPI"
        // let API_URL = API_URL

        // API_URL="http://localhost:5000/appycx-beta/us-central1"
        var body = [`url=${API_URL}&token=${viberAccessToken}&botId=${localStorage.getItem("botId")}`]
        console.log('installvibre func --->>>>>', body)
        try {
            fetch(API_URL + "/connect_viber/set", {
                method: 'POST',
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body
            }).then(result => {

                console.log(result, '-->>result', 'second-------------->>>---------------------')
                let viberData = {
                    alias: localStorage.getItem("tablealias"),
                    agentID: localStorage.getItem("agent_id"),
                    BOTID: localStorage.getItem("botId"),
                    Token: viberAccessToken,
                    action: action,
                    Botname: localStorage.getItem("BOTNAME"),
                    // LineUsername: result.basicId,
                    // displayName:result.displayName,
                    // docId:result.userId,
                    Bottype: parseInt(localStorage.getItem('botType')),
                    isActive: true
                }
                console.log(viberData, '-->>viberdata')
                //  console.log('--->>vibersetup func--->>')
                vibersetup(viberData, action, localStorage.getItem("botId"))
                    //  console.log('---->>>>vibersetup function--')
                    .then((response) => {
                        console.log("++++++++ Response ++++++++", response)
                        let msg;

                        if (response) {
                            if (action == "setup") {
                                if (response) {
                                    setIsInstalled(true)
                                    setEnable(true)
                                    setIsLoading(false)
                                    setViberData(viberData)
                                    setViberAccessToken(viberAccessToken)
                                    msg = "Bot deployed successfully"
                                    //   setShowDeactivate(true)
                                    // msg = "Bot has been connected to viber server"
                                    // var bodyExtraShdow = document.getElementById("root");
                                    // bodyExtraShdow.classList.toggle("bodyExtraShdow");
                                } else {
                                    setIsLoading(false)
                                    NotificationManager.error('Invalid bot token', '' , 2000)
                                }
                            }
                            else if (action == "enable") {
                                
                                setEnable(true)
                                setIsLoading(false)
                                msg = "Bot enabled successfully"
                            } else if (action == "disable") {
                                setEnable(false)
                                setIsLoading(false)
                                msg = "Bot disabled successfully"
                            } else {
                                setViberData({})
                                setIsLoading(false)
                                setIsInstalled(false)
                                setEnable(false)
                                setViberAccessToken("")
                                msg = "Bot uninstalled successfully"
                            }
                            setIsLoading(false)


                            NotificationManager.success(msg, '' , 2000)
                        } else {
                            NotificationManager.error('Error updating bot status', '' , 2000)
                        }

                    })
            })

        } catch (error) {
            console.log(error, '---->>>>>>>error')
        }

    }


    useEffect(() => {
        getDeployedViberList(localStorage.getItem("botId")).then((res) => {
            console.log("======= Viber List ======= ", res)
            if (res.length > 0) {

                setViberData(res[0])
                setIsLoading(false)
                setEnable(res[0].isActive)
                setIsInstalled(true)
                setViberAccessToken(res[0].Token)


            } else {
                setIsLoading(false)
            }
        })
    }, [])





    const togggleViberstatus = (e) => {
        if(enable){
            handleViberSetup("disable")
        }
        else{
            handleViberSetup("enable")
        }
    }

    return (
        <>
            {isLoading ? <SetupLoader/> :
                <div className="flex flex-col bg-[#fdfdfd] border rounded-xl p-4">
                        <p className="font-medium text-lg">Deploy your bot in your Viber account.</p>

                    {isInstalled ? <>
                        {/* <p className="font-medium text-sm">Deploy your bot in your Viber account.</p> */}
                        {/* <div className="flex items-center justify-center py-3 rounded mt-2 ">
                            
                            <span className="switchDeplyLink ml-auto">
                                <label for="toggle" className="flex cursor-pointer">
                                    <div className="relative">
                                        <input checked={enable} onChange = {togggleViberstatus} type="checkbox" id="toggle" className="sr-only" />
                                        <div className={enable ? activeCss : disableCss}></div>
                                        <div className="dot absolute left-1 top-1 bg-gray-300 w-4 h-4 rounded-full transition"></div>
                                    </div>
                                </label>
                            </span>
                            <span className="deleteDeplyLink ml-3 cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20.849" height="23.949" viewBox="0 0 10.849 13.949">
                                    <path id="Icon_material-delete-forever" data-name="Icon material-delete-forever" d="M8.275,16.9a1.554,1.554,0,0,0,1.55,1.55h6.2a1.554,1.554,0,0,0,1.55-1.55V7.6h-9.3Zm1.906-5.517,1.093-1.093,1.651,1.643,1.643-1.643,1.093,1.093-1.643,1.643,1.643,1.643L14.567,15.76l-1.643-1.643L11.282,15.76l-1.093-1.093,1.643-1.643Zm5.455-6.106L14.862,4.5H10.987l-.775.775H7.5v1.55H18.349V5.275Z" transform="translate(-7.5 -4.5)" fill="#5269c9" />
                                </svg>
                            </span>
                        </div> */}
                        <p className="text-sm w-10/12 font-light">Viber Account</p>
                        <div className='flex items-center gap-4 mt-5'>
                          <button className=' w-36 bg-[#5371ff] drop-shadow-sm p-2 text-center rounded-3xl font-light text-white whitespace-nowrap hover:drop-shadow-xl' onClick = {togggleViberstatus}  >{enable ? 'Disable' : 'Enable'}</button>
                          <button className=' w-36  border border-[#404040] drop-shadow-sm p-2 text-center rounded-3xl font-light text-[#404040] whitespace-nowrap hover:drop-shadow-xl'  onClick={() => handleViberSetup('uninstall')} >Delete</button>
                         </div>
                        </> : <>
                        <div className="botWordpress customeWebsiteWidget">
                    <div className="widgetParaGraphGuideLine py-5">
                      {/* <p className="text-sm pb-1">Deploy your bot in your Viber account.</p> */}
                      <p className="text-sm">
                      Enter the access key (bot token)  
                      {/* <a href="#" target='_blank' className='text-sm text-blue-500'>How I get the access key</a> */}
                      
                      </p>
                    </div>
                    <div className="watchGuideVideo">
                          <input value={viberAccessToken} onChange = {(event) => setViberAccessToken(event.target.value)} type="text" className="px-3 border mr-2 py-1 rounded h-9 w-2/4" placeholder='Bot Token' />
                          <button onClick={() => handleViberSetup('setup')} className="h-9 border border-indigo-600 text-sm px-5 py-1 text-center rounded text-indigo-600 bg-indigo-600 text-white">Enable</button>
                    </div>
                  </div>
                        </>}
                </div>}
        </>
    )
}

export default Viber