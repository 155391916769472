import { set } from 'date-fns'
import React, { useState , useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { handleAuthRedirect } from 'realm-web'
import Facebook from '../../setup/socialMediaPlatform/Facebook'
import Line from '../../setup/socialMediaPlatform/Line'
import Telegram from '../../setup/socialMediaPlatform/Telegram'
import Viber from '../../setup/socialMediaPlatform/Viber'
import Whatsapp from '../../setup/socialMediaPlatform/Whatsapp'
import CustomDropdown from './CustomDropdown'

function SocialAppsMobile(props) {

  const navigate = useNavigate()

  //destructuring props
  const {onlySocialAppsData , defaultSocialAppInMobile} = props


  //local states
  const [allowedApps , setAllowedApps] = useState(null)
  const [selectedApp ,setSelectedApp] = useState(null)
  const [displayIconUrl , setDisplayIconUrl] = useState(null)




  useEffect(() => {
    console.log(onlySocialAppsData , '   onlySocialAppsData')
    const allApps = onlySocialAppsData.apps
    const allowedApps= allApps.filter((item) => {
      return item.botlist.includes(parseInt(localStorage.getItem("botType")))
    })
    const allowedAppsIds = allowedApps.map((item) => {
      return item.id
    })
    console.log(allowedApps , '   allowedApps')
    const customisedOptions = allowedApps.map((item) => {
      return {
       ...item,
       imageUrl : item.img
        
      }
    })
    setAllowedApps(customisedOptions)
    
    if(defaultSocialAppInMobile && allowedAppsIds.includes(defaultSocialAppInMobile)){
      setSelectedApp(defaultSocialAppInMobile)
      setDisplayIconUrl(customisedOptions.filter((item) => item.id === defaultSocialAppInMobile)[0].imageUrl)
    }
    else{
      navigate(`/cx/Chatbot/${localStorage.getItem("bot_type")}/setup/${allowedApps[0].id}?did=${localStorage.getItem("botId")}`)
    }

   

  },[defaultSocialAppInMobile])


   //handler function
    //setting the selected app
    const handleChange = (option) => {
      // setSelectedApp(event.target.value)
      navigate(`/cx/Chatbot/${localStorage.getItem("bot_type")}/setup/${option.id}?did=${localStorage.getItem("botId")}`)

    }

  return (
    <div className='mt-4 pt-4  border-gray-200 border-t mobile-social'> 
        <h3 className='text-[#334763] text-base font-semibold'>Install bot on your social media apps</h3>
        <p className='my-4 text-sm text-[#334763]'>Let your visitors contact you via chatbot by adding chat widget to your website.</p>

        <h4 className='text-[#767C87] text-[13px] mb-2'>Select platform</h4>
        {/* <select className='border-gray-200 border w-full p-4 rounded-lg text-sm mb-4' name="" id="" value={selectedApp} onChange = {(event) => handleChange(event)}  >
        {allowedApps && allowedApps.map((option) => {
            return <option value={option.id}>{option.title}</option>
        })}
        </select> */}

        {/* custom dropdown  */}
        {allowedApps && <CustomDropdown options = {allowedApps} handleOptionChange = {handleChange} value = {selectedApp}  imageInCloud = {true} displayIconUrl = {displayIconUrl}/>}


        {selectedApp === 'telegram' && <Telegram/>}
        {selectedApp === "whatsapp" && <Whatsapp/>}
        {selectedApp === "line" && <Line/>}
        {selectedApp === "viber" && <Viber/>}
        {selectedApp === "facebook_messenger" && <Facebook/>}


    </div>
  )
}

export default SocialAppsMobile