import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { API_URL } from '../../../config/params'
import { getDeployedLineList } from '../../../utilities/chatbotMethods'
import { NotificationManager } from 'react-notifications'
import { Linesetup } from '../../../utilities/chatbotMethods'
import StepLoader from '../../loaders/StepLoader'
import SetupLoader from '../../loaders/SetupLoader'
function Line() {
    const [isLoading, setIsLoading] = useState(true)
    const [isInstalled, setIsInstalled] = useState(false)
    const [enable, setEnable] = useState(false)
    const [lineData, setLineData] = useState({})
    const [lineToken, setLineToken] = useState("")
    useEffect(() => {

        getDeployedLineList(localStorage.getItem('botId')).then((data) => {
            if (data.length > 0) {
                setLineData(data[0])

                setEnable(data[0].isActive)
                setIsInstalled(true)

            }
            setIsLoading(false)
        })
    }, [])

    const toggleLineStatus = () => {

        if (enable) {
            handleLine("disable")
        } else {
            handleLine("enable")
        }
    }


    async function handleLine(action) {
        setIsLoading(true)
        // {BOTID:"BOTID1622020512038"
        // Botname:"inquiry"
        // Bottype:"0"
        // LineUsername:"@800fvrqi"
        // Token:"ZV7MuEo5QfRzUL0SKlwJ6Q/riowFNV8lxQYHcxWVbEIw8hwkTR4/TIgryW3ZPrjzQpLBPOBKzekAyHm6VYD59f9J+gpWElrUH5E2min7ZX3F+XStr35yhegpljMQryxFVj0b3dzSuJKnvQCilE0GXwdB04t89/1O/w1cDnyilFU="
        // agentID:"AGENT1603259591478"
        // aliass:"kgizd646"
        // chatMode:"bot" 
        // displayName:"HelloSP"
        // isActive:true}
        // if (entoken){setlineToken(entoken)}
        // var fetchurl = 'https://us-central1-appycx-beta.cloudfunctions.net/LineSetupAPI'
        var fetchurl = API_URL + '/LineSetupAPI'


        // fetchurl="http://localhost:5000/appycx-beta/us-central1/LineSetupAPI"
        var body = { "token": lineToken }
        console.log(body, "BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB")
        fetch(fetchurl,
            {
                headers: { "Content-Type": "application/json" },
                method: 'POST',
                body: JSON.stringify(body)
            }).then(response => response.json())
            .then(result => {
                console.log(result, "lineapi")
                if(result.basicId){

                
                let LineData = {
                    alias: localStorage.getItem("tablealias"),
                    agentID: localStorage.getItem("agent_id"),
                    BOTID: localStorage.getItem("botId"),
                    Token: lineToken,
                    action: action,
                    Botname: localStorage.getItem("BOTNAME"),
                    LineUsername: result.basicId,
                    displayName: result.displayName,
                    docId: result.userId,
                    Bottype: parseInt(localStorage.getItem('botType')),
                    isActive: true
                }
                Linesetup(LineData, action)
                    .then((response) => {
                        console.log("++++++++ Response ++++++++", response)
                        let msg;
                        if (response) {
                            if (action == "setup") {
                                if (response) {
                                    setLineData(LineData)
                                    setIsLoading(false)
                                    setEnable(true)
                                    setIsInstalled(true)
                                    msg = "Bot deployed successfully"
                                } else {
                                    setIsLoading(false)
                                    NotificationManager.error('Invalid bot token', '' , 2000)
                                }
                            }
                            else if (action == "enable") {
                                setEnable(true)
                                setIsLoading(false)
                                msg = "Bot has been enable from Line"

                            } else if (action == "disable") {
                                setEnable(false)
                                setIsLoading(false)
                                msg = "Bot has been disable from Line"

                            } else {
                                setLineData({})
                                setIsLoading(false)
                                setIsInstalled(false)
                                setEnable(false)
                                msg = "Bot has been disconnected from Line"
                            }
                            setIsLoading(false)
                            NotificationManager.success( msg, '' , 2000)
                        } else {
                            NotificationManager.error( 'Error updating bot status', '' , 2000)
                        }
                    })

                }else{
                    setIsLoading(false)
                    NotificationManager.error('Invalid bot token', '' , 2000)
                }
            })

    }

    return (


        <>

            {isLoading ? <SetupLoader/> :

                <div className="botWordpress customeWebsiteWidget px-5 py-5">
                
                    {isInstalled ? <>
                        <div className='flex flex-col bg-[#F4F5F9] border rounded-xl p-4'>

                            <h2 className='text-xl font-semibold mb-4'>{lineData.displayName}</h2>
                            <span className='fnt-bold'>{"https://line.me/R/ti/p/" + lineData.LineUsername}</span>


                            {/* <input type="button" name="isEnable" id="isEnable" checked={enable} onChange = {(event) => {toggleTelegramStatus(event.target.value)}} /> */}
                            
                            <div className='flex items-center gap-4 mt-5'>
                            <button className=' w-36 bg-[#5371ff] drop-shadow-sm p-2 text-center rounded-3xl font-light text-white whitespace-nowrap hover:drop-shadow-xl' onClick={() => { toggleLineStatus() }}>{enable ? 'Disable' : 'Enable'}</button>
                            <button className=' w-36  border border-[#404040] drop-shadow-sm p-2 text-center rounded-3xl font-light text-[#404040] whitespace-nowrap hover:drop-shadow-xl' onClick={() => handleLine('uninstall')}>Delete</button>
                            </div>
                        </div>
                    </> : <>
                        <div className="widgetParaGraphGuideLine py-5">
                            <p className="text-sm pb-1">Deploy your bot in your Line account.</p>
                            <p className="text-xs mt-2">
                                Enter the access key (bot token)  <a className="text-sm text-blue-500" target='_blank' href="https://snappy.desk.appypie.com/kb/article/how-to-deploy-appypies-chatbot-widget-in-line">
                                    How I get the access key
                                </a>
                            </p>
                            <form onSubmit={() => { handleLine("setup") }
                            }>
                                {/* <label  htmlFor="">Enter the access key (bot token)</label> */}
                                <input className='mt-3 px-3 border mr-2 py-1 rounded h-9 w-2/4' type="text" value={lineToken} onChange={(event) => setLineToken(event.target.value)} required placeHolder='Token' />
                                <input className='h-9 border border-indigo-600 text-sm px-5 py-1 text-center rounded cursor-pointer bg-indigo-600 text-white' type="submit" value='Install' />
                            </form>
                        </div>
                    </>
                    }

                </div>
            }

        </>


    )
}

export default Line