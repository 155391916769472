import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getInstalledIntegrationsAsync } from "./integrationThunk"

const initialState = {
    installedIntegrations: [],
    dgh : 'jnhadgsvhj'

}




//thunk
export const getInstalledIntegrations = createAsyncThunk('integration/getInstalledIntegration', getInstalledIntegrationsAsync)


const integrationSlice = createSlice({
    name: 'integration',
    initialState,
    reducers: {
        updateinstalledIntegrations: (state, action) => {
            state.installedIntegrations = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getInstalledIntegrations.fulfilled, (state, action) => {
            console.log(action.payload, " payload")
            state.installedIntegrations = action.payload
        })
    }
})






export const { updateinstalledIntegrations } = integrationSlice.actions
export default integrationSlice.reducer