import { set } from 'date-fns';
import { el } from 'date-fns/locale';
import React, { useState , useEffect } from 'react'
import { useLocation, useNavigate , useParams } from 'react-router-dom';
import { db } from "./../../../config/firebase";
import SocialAppsMobile from './SocialAppsMobile';
import Website from './Website';

function SetupMobile(props) {

    //destructuring props
    const {setupData , defaultSocialAppInMobile} = props



    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()


    //constants
    // const DEFAULT_TABS = ['Deploy bot in website' , 'Deploy bot on social media apps']

    //local states
    const [tabs , setTabs] = useState(null)
    const [selectedTab , setSelectedTab] = useState(null)
    const [allowedSetupData , setAllowedSetupData] = useState(null)
    const [onlySocialAppsData , setOnlySocialAppsData] = useState(null)
    const [isLoading , setIsLoading] = useState(true)
    




  



    //handler function
    const handleTabClick = (index) => {
        setSelectedTab(selectedTab === index ? null : index)
        if(index === 0){
            navigate(`/cx/Chatbot/${localStorage.getItem("bot_type")}/setup?did=${localStorage.getItem("botId")}`)
        }
        
    }

    //useEffect
    useEffect(() => {
        console.log(setupData , '   setupData')
        const deploymentOptions = setupData.deployment_options
        setIsLoading(true)

        //filtering the setup data
        //getting only the allowed options anf their data
        const allowedOptions =  deploymentOptions.filter(item => {
            return !item.botlist || (item.botlist && item.botlist.includes(parseInt(localStorage.getItem("botType")))) && item.id !== 'sdk'
        })
        console.log(allowedOptions , '   allowedOptions')

        //setting local states
        setAllowedSetupData(allowedOptions)
        setTabs(allowedOptions.map(item => item.desc))
        // setAllowedAppIds(allowedOptions.map(item => item.id))
        setOnlySocialAppsData(allowedOptions.filter(item => item.id === 'social_media_platform')[0])

        //if appType is present then opening the social app by default
        if(defaultSocialAppInMobile){
            setSelectedTab(1)
        }
        else{
            setSelectedTab(null)
        }
        setIsLoading(false)
        
        
    },[])


   

    
  return (
    <div className='traingpt-mobileview'>

        {/* <div className='flex justify-between py-3 px-3 items-center bg-white'>
            <button type='button' onClick={handleBackClick } className = "cursor-pointer p-2">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 20 20" aria-hidden="true" height="23" width="23" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M17 10a.75.75 0 01-.75.75H5.612l4.158 3.96a.75.75 0 11-1.04 1.08l-5.5-5.25a.75.75 0 010-1.08l5.5-5.25a.75.75 0 111.04 1.08L5.612 9.25H16.25A.75.75 0 0117 10z" clip-rule="evenodd"></path></svg>
            </button>
            <h2 className='text-[#334763] text-lg font-medium mb-0 text-center mx-auto'>Widget Setup</h2>
        </div> */}


        {isLoading ? ""  :  <div className=' mr-5 ml-5 mt-5 rounded-lg'>
            {tabs.map((tab , index) => {
                return <div key={index} className='bg-[#fff] rounded-lg shadow-xl  p-5 mb-5 cursor-pointer' >
                    <div className=' flex justify-between' onClick={() => {handleTabClick(index)}}>
                        <h2 className='text-[#334763] text-base font-semibold'>{tab}</h2>
                        <span className={`arrowright ${selectedTab === index && 'active'}`}><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="25" width="25" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M8.59 16.59 13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path></svg></span>
                    </div>
                    {selectedTab === index ? index === 0 ? <Website/>  : <SocialAppsMobile defaultSocialAppInMobile = {defaultSocialAppInMobile} onlySocialAppsData = {onlySocialAppsData}/>   : null}
                </div>
            })}
        </div>}



    </div>
  )
}

export default SetupMobile