

import React , {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getAgentData } from '../../features/agent/agentSlice'
import { getDashboardData } from '../../features/userBots/userBotsSlice'
import { userBots } from '../../server/service'
import RestrictEditor from './RestrictEditor'
import SetupMobile from './setup/SetupMobile'
import strings from './../../data/en.json'
import { stopLoader } from '../../utilities/chatbotMethods'
import { IFRAME_URL } from '../../config/params'
import { getBotFlowChartData, resetTemplate, updateTemplate } from '../../features/botTemplate/botTemplateSlice'



function MobilePreview() {


    const params = useParams()
    const location = useLocation()

    //initiliazing dispatch
    const dispatch = useDispatch()

    //getting data from the global state
    const {userBots} = useSelector(state => state.userBots)
    const {template} = useSelector(state => state.botTemplate)

    //local states   
    const [showPage , setShowPage] = React.useState(null)
    const [botData , setBotData] = React.useState(null)
    const [isLoading , setIsLoading] = React.useState(true)
    const [buttonText , setButtonText] = React.useState('Edit Flow')

    const navigate = useNavigate()
    //handler function
    const handleEditClick = () => {
        console.log('edit clicked')
        // setShowRestrictEditor(true)
        // setShowPage('restrict-editor')
        if(localStorage.getItem('botType') == '36'){
            navigate(`/cx/Chatbot/${localStorage.getItem('bot_type')}/traingpt?did=${localStorage.getItem('botId')}`)
        }
        else{
            navigate('/mobile/editor?did=' + localStorage.getItem('botId'))
        }
        
        
    }

    //handler function
    const handleDeployClick = (botData) => {
        console.log('deploy clicked')
        const navigateTo = createLink(botData)
        
        navigate(navigateTo)
    }


    //handler function
    const handleBackClick = () => {
        console.log('back clicked')

        // if(localStorage.getItem('botType') == '36'){
        //     navigate(`/cx/Chatbot/${localStorage.getItem('bot_type')}/traingpt?did=${localStorage.getItem('botId')}`)
        // }
        // else{
            navigate('/cx/Chatbot')
        // }
       
    }

    


    //getting dash data from the bot id
    //setting the local storage  - bot id , bot type , bot name
    useEffect(() => {
        // stopLoader("*")

        if(!userBots){
            dispatch(getDashboardData())
            dispatch(getAgentData())
            
           
        }
        else{
        const urlParams = new URLSearchParams(window.location.search)
        const botId = urlParams.get('did')
        console.log(botId , '  mobile bot id')

        if(!botId){
          return  navigate('/cx/Chatbot')
        }
        
        localStorage.setItem('botId',botId)
        console.log(userBots," userBots ",botId)
        var dashData=Object.keys(userBots).includes(botId)?userBots[botId]:null
        
        if(dashData){
            
            const foundBot = strings.botList.find(obj => obj.id==dashData.bot_template_category)

            
            console.log(dashData , ' dashdata')
            console.log(foundBot , ' foundbot')
            
            
            // console.log(foundBot , 'fb')
            try {
            const newBotType = foundBot!== undefined ? foundBot.botType !== undefined ? foundBot.botType : foundBot?.button.toLocaleLowerCase().replace(' bot' , '').trim().replace(/[\s!@#$%^&*()_+{}\[\]:;<>,.?\/\\\-='"]/g, '-'):dashData.type?.toLowerCase().replace(' bot' , '').trim().replace(/[\s!@#$%^&*()_+{}\[\]:;<>,.?\/\\\-='"]/g, '-')
            if(newBotType!==undefined){
                localStorage.setItem('bot_type' , newBotType)
                }
            
            } catch (error) {
            console.log(error,"errorfoundbot")
            }
            // const newBotType = foundBot!==undefined ? foundBot.botType!==undefined ? foundBot.botType : foundBot.button.toLocaleLowerCase().replace(' bot' , '').trim().replace(/[\s!@#$%^&*()_+{}\[\]:;<>,.?\/\\\-='"]/g, '-'):dashData.type.toLowerCase().replace(' bot' , '').trim().replace(/[\s!@#$%^&*()_+{}\[\]:;<>,.?\/\\\-='"]/g, '-')
            
            localStorage.setItem("botType",dashData.bot_template_category);
            localStorage.setItem("botId", botId);
            
            localStorage.setItem('BOTNAME',dashData.bot_name)
            setBotData(dashData)
            setButtonText(dashData.bot_template_category == '36' ? 'Configure' : 'Edit Flow')
            stopLoader("*")
            
        }

        
        
        
    }


    },[userBots])


    useEffect(() => {
        if(params.page === 'editor'){
            setShowPage(params.page)
            }
            else{
                setShowPage(null)
            }
    },[location])

    useEffect(() => {
        // if(template){

        // }else{
            dispatch(resetTemplate())
            const urlParams = new URLSearchParams(window.location.search)
            const botId = urlParams.get('did')
            dispatch(getBotFlowChartData(botId))

        // }
    },[])

    useEffect(() => {
        if(template){
            dispatch(updateTemplate(template))
        }
    },[template])




    
    const createLink=(data)=>{

    console.log(data , ' hjkbkljl;kl;k')
    // console.log(botList , ' ehs ' , data)
    if(data.bot_template_category==5){
        return `/cx/Chatbot`
    }
    const foundBot = strings.botList.find(botObj => botObj.id==data.bot_template_category)
    
    // console.log(foundBot , 'fb')
    const newBotType = foundBot? foundBot.botType ? foundBot.botType : foundBot.button.toLocaleLowerCase().replace(' bot' , '').trim().replace(/[\s!@#$%^&*()_+{}\[\]:;<>,.?\/\\\-='"]/g, '-'):data.type;
    
    return `/cx/Chatbot/${newBotType}/setup?did=${localStorage.getItem('botId')}`

    }



    //callback function
    const handlePageChange = (page) => {
        setShowPage(page)
    }
  return (
   <>
   {false ? <div>Loading...</div> :  <div className='traingpt-mobileview'>


{showPage === 'editor' ? <RestrictEditor handlePageChange = {handlePageChange}/>  :   <>
<div className='flex justify-between py-3 px-3 items-center absolute top-0 left-0 w-full h-[85px] z-[1] bg-[#f5f6f7]'  style={{
  
}}>
     <button type='button' onClick={handleBackClick} className = "cursor-pointer p-2">
         <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 20 20" aria-hidden="true" height="23" width="23" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M17 10a.75.75 0 01-.75.75H5.612l4.158 3.96a.75.75 0 11-1.04 1.08l-5.5-5.25a.75.75 0 010-1.08l5.5-5.25a.75.75 0 111.04 1.08L5.612 9.25H16.25A.75.75 0 0117 10z" clip-rule="evenodd"></path></svg>
     </button>
     <h2 className='text-[#334763] text-lg font-medium mb-0 text-center mx-auto'>Preview</h2>
 </div>
 <div className='iframe-chatwidget'>
     {/* <iframe style={{height : '100%', width : '100%', minHeight: '90vh'}} src={`https://chatwidget.bot/mobilebot.html?cid=${localStorage.getItem('tablealias')}&botid=${localStorage.getItem('botId')}&agent=${localStorage.getItem('agent_id')}`} frameborder="0"></iframe> */}
     <iframe id="patil" style={{height : '100%', width : '100%',}} src={`${IFRAME_URL}?companyId=${localStorage.tablealias}&botId=${localStorage.botId}&agentId=${localStorage.agent_id}&isPreview=true`} frameborder="0"></iframe>
 </div>
 <div className='fixed bottom-0 z-[2] bg-white w-full h-auto text-center left-0 shadow-mobile flex items-center justify-center gap-3 border-middle'>
     <button className='cursor-pointer text-[#5973F7] text-base bg-white block font-[500] px-3 py-5 text-center w-full' onClick={handleEditClick}>{buttonText}</button>
     <button className='cursor-pointer text-[#5973F7] text-base bg-white block font-[500] px-3 py-5 text-center w-full' onClick={() => handleDeployClick(botData)}>Save & deploy</button>
 </div>
 </>}
</div>}
   </>
  )
}

export default MobilePreview